import log from "loglevel";
import type { CacheEntry } from "./CacheEntry";

/**
 * Retrieves a cached response from session storage.
 *
 * @typeParam T - The type of the cached value.
 *
 * @param key - The key under which the cached response is stored.
 *
 * @returns The cached value if it exists and is still valid; otherwise,
 * undefined.
 */
export function getCachedResponse<T>(key: string): T | undefined {
  try {
    // Retrieve cache entry.
    const item = sessionStorage.getItem(key);
    if (!item) return;

    // Parse the cache entry
    const { value, timestamp, ttl } = JSON.parse(item) as CacheEntry<T>;

    // Check if the cache entry has expired
    if (Date.now() - timestamp > ttl) {
      sessionStorage.removeItem(key);
      return;
    }

    // Return the cached value.
    return value;
  } catch (error) {
    log.warn("Cache read failed:", error);
    return;
  }
}
