import log from "loglevel";
import { getCacheKey } from "./getCacheKey";

/**
 * Invalidates one or more cache entries based on the provided key
 *
 * @param key - The cache key to invalidate
 * @param startsWith - When true, invalidates all cache entries whose keys start
 * with the provided key. Otherwise, only invalidates the exact matching key.
 */
export function invalidateCache(
  key: string,
  startsWith?: boolean | undefined
): void;

/**
 * Invalidates a specific cache entry using a key generated from the base key
 * and parameters
 *
 * @param key - The base key to use for cache invalidation
 * @param parameters - Parameters that will be combined with the base key to
 * generate the full cache key
 */
export function invalidateCache(
  key: string,
  parameters?: Record<string, unknown> | undefined
): void;

/**
 * Implementation signature
 *
 * @param key - The cache key or base key to use for invalidation
 * @param startsWithOrParameters - Either a boolean flag for prefix matching or
 * parameters to generate the full cache key
 *
 * @internal
 */
export function invalidateCache(
  key: string,
  startsWithOrParameters?: boolean | Record<string, unknown> | undefined
): void {
  // Parse startsWithOrParameters
  const [startsWith, parameters] =
    typeof startsWithOrParameters === "boolean"
      ? [startsWithOrParameters, {}]
      : [false, startsWithOrParameters];

  // Get proper cache key including internal prefix.
  const cacheKey = getCacheKey(key, parameters);

  try {
    if (startsWith) {
      // Remove all cache entries with a key starting with the cacheKey value.

      Object.keys(sessionStorage)
        .filter((item) => item.startsWith(cacheKey))
        .forEach((item) => sessionStorage.removeItem(item));
    } else {
      // Remove the cache entry with a key that exactly matches.

      sessionStorage.removeItem(cacheKey);
    }
  } catch (error) {
    log.warn("Cache invalidation failed:", error);
  }
}
