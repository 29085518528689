











import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ inheritAttrs: false })
export default class AsyncButton extends Vue {
  /** Callback. */
  @Prop({
    type: Function,
    required: true,
    validator: (value) => value.constructor.name === "AsyncFunction",
  })
  readonly callback!: (...params: unknown[]) => Promise<void>;

  /** Callback parameters. */
  @Prop({ type: Array, default: () => [] })
  readonly params!: unknown[];

  @Prop({ type: Boolean, default: () => false })
  readonly customLoading!: boolean;

  /**
   * Internal loading flag.
   * Used if custom loading is true to prevent clicks during loading.
   */
  internalLoading = false;

  /** Button loading flag. */
  loading = false;

  /** Button on click handler. */
  async onClickHandler(event: MouseEvent): Promise<void> {
    if (!this.customLoading) this.loading = true;
    else this.internalLoading = true;

    await this.callback(...this.params, event);
    await this.$nextTick();

    this.loading = false;
    this.internalLoading = false;
  }
}
