import { paramsSerializer } from "../Api/paramsSerializer";
import { PREFIX } from "./PREFIX";

/**
 * Generates a cache key based on a base string and an optional set of
 * parameters.
 *
 * @param base - The base string to use for the cache key.
 * @param parameters - An optional record of parameters to include in the cache
 * key.
 *
 * @returns A string representing the cache key.
 */
export function getCacheKey(
  base: string,
  parameters: Record<string, unknown> = {}
): string {
  const hasParameters = Object.keys(parameters).length > 0;
  if (!hasParameters) return `${PREFIX}:${base}`;

  // paramsSerializer also sorts the params.
  const serializedParameters = paramsSerializer(parameters);

  return `${PREFIX}:${base}?${serializedParameters}`;
}
