import Loader from "@/components/Loader.vue";
import { clearCache } from "@/lib/Cache/clearCache";
import { Router } from "@/plugins/Router";
import log from "loglevel";
import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
} from "oidc-client";
import { PluginFunction } from "vue";
import { ExpiredUserError, MissingUrlsError, NoUserFoundError } from "./errors";
import { UserContext } from "./state";
import { IAuthUrls, User } from "./types";

export class Auth {
  //#region OIDC Client Settings

  /** OIDC Client ID */
  private clientId = "taqsjs";

  /** OIDC Client secret */
  private clientSecret = "secret";

  /** OIDC Client scope */
  private scope = ["openid", "profile", "roles", "taqsapi", "userinfo"];

  //#endregion

  //#region OIDC Manager

  /** OIDC Client User Manager */
  private manager: UserManager;

  constructor({ authority, signInUrl, signOutUrl, silentUrl }: IAuthUrls) {
    /* eslint-disable camelcase */
    const settings: UserManagerSettings = {
      authority: authority.toString(),
      automaticSilentRenew: "onLine" in navigator ? navigator.onLine : true,
      client_id: this.clientId,
      client_secret: this.clientSecret,
      post_logout_redirect_uri: signOutUrl.toString(),
      redirect_uri: signInUrl.toString(),
      response_type: "code",
      scope: this.scope.join(" "),
      silent_redirect_uri: silentUrl.toString(),
      userStore: new WebStorageStateStore({ store: localStorage }),
    };
    /* eslint-enable camelcase */

    // Set the user manager.
    this.manager = new UserManager(settings);

    this.manager.events.addUserLoaded((oidcUser) => {
      // Allow only updates of the user object after it and settings are set once.
      if (UserContext.state.user && UserContext.state.settings) {
        UserContext.actions.setUser(oidcUser);
      }
    });

    // Sign out stuff.
    this.manager.events.addAccessTokenExpired(() => {
      Router.push({ name: "SignOut" });
    });
    this.manager.events.addUserSignedOut(() => {
      // Workaround for issue in local development environment.
      if (location.hostname === "localhost") return;
      Router.push({ name: "SignOut" });
    });

    // Reset on silent renew error.
    this.manager.events.addSilentRenewError(() => {
      log.warn("Silent Renew Error Caught.");
    });

    // Restart app.
    this.manager.events.addUserUnloaded(() => {
      sessionStorage.setItem(
        "return-url",
        location.pathname + location.search + location.hash
      );
      Router.push({ name: "App" });
    });
  }

  private get user() {
    return UserContext.state.user;
  }

  async getUser(): Promise<User> {
    if (this.user) {
      return this.user;
    }

    const oidcUser = await this.manager.getUser();

    if (oidcUser) {
      await UserContext.actions.setUser(oidcUser);

      const user = UserContext.state.user;
      if (user) {
        return user;
      }

      throw new ExpiredUserError();
    }

    throw new NoUserFoundError();
  }

  async signIn(): Promise<void> {
    await this.manager.signinRedirect();
  }

  private async signOut() {
    await this.manager.signoutRedirect();
  }

  private async signInCallback() {
    await this.manager.signinRedirectCallback();
  }

  private async signOutCallback() {
    await this.manager.signoutRedirectCallback();
  }

  private async silentCallback(): Promise<User> {
    const oidcUser = await this.manager.signinSilentCallback();

    if (oidcUser) {
      await UserContext.actions.setUser(oidcUser);

      const user = UserContext.state.user;
      if (user) {
        return user;
      }

      throw new ExpiredUserError();
    }

    throw new NoUserFoundError();
  }

  private async stopSilentRenew() {
    this.manager.stopSilentRenew();
  }

  private async startSilentRenew() {
    this.manager.startSilentRenew();
  }

  //#endregion

  //#region Vue Plugin

  static install: PluginFunction<IAuthUrls> = function (Vue, urls) {
    if (!urls) {
      throw new MissingUrlsError();
    }

    // Create new auth instance.
    const auth = new Auth(urls);

    // Add sign in/out routes.
    Router.addRoutes([
      {
        name: "SignIn",
        path: "/sign-in",
        component: Loader,
        beforeEnter: (to, from, next) => {
          if ("code" in to.query && "code" in from.query) {
            next({ name: "SignOut" });
          } else if ("code" in to.query) {
            auth
              .signInCallback()
              .then(() => {
                auth
                  .getUser()
                  .then(() => {
                    // Clear any left-over cache that may be form other users.
                    clearCache();
                    next({ name: "App" });
                  })
                  .catch((error) => {
                    if (error instanceof ExpiredUserError) {
                      next({ name: "SignOut" });
                    } else if (error instanceof NoUserFoundError) {
                      log.error("SIGN_IN: No user found?");
                      UserContext.actions.unsetUser().then(() => {
                        next({ name: "App" });
                      });
                    }
                  });
              })
              .catch((error: unknown) => {
                if (
                  error instanceof Error &&
                  error.message === "No matching state found in storage"
                ) {
                  next({ name: "SignOut" });
                } else {
                  throw error;
                }
              });
          } else {
            next({ name: "App" });
          }
        },
      },
      {
        name: "SignOut",
        path: "/sign-out",
        component: Loader,
        beforeEnter: (to, from, next) => {
          auth
            .getUser()
            .then(() => {
              auth.signOut().finally(() => {
                sessionStorage.clear();
              });
            })
            .catch((error) => {
              if (error instanceof ExpiredUserError) {
                auth.signOut().finally(() => {
                  sessionStorage.clear();
                });
              } else if (error instanceof NoUserFoundError) {
                // Returned after sign out.
                auth.signOutCallback().finally(() => {
                  UserContext.actions.unsetUser().then(() => {
                    /* Currently covered by sessionStorage.clear() but the
                    implementation might change so calling clearCache anyway
                    to be safe. */
                    clearCache();
                    sessionStorage.clear();
                    next({ name: "App" });
                  });
                });
              } else {
                throw error;
              }
            });
        },
      },
      {
        name: "Silent",
        path: "/silent",
        component: { render: (h) => h("span") },
        beforeEnter: (to, from, next) => {
          auth
            .silentCallback()
            .then(() => {
              next();
            })
            .catch((error) => {
              if (
                error instanceof NoUserFoundError ||
                error instanceof ExpiredUserError
              ) {
                next(false);
              } else {
                throw error;
              }
            });
        },
      },
    ]);

    // Start and stop silent renew on online and offline events.
    window.addEventListener("online", () => {
      auth.startSilentRenew();
    });
    window.addEventListener("offline", () => {
      auth.stopSilentRenew();
    });

    // Set auth prototype.
    Vue.prototype.$auth = Vue.observable(auth);
  };

  //#endregion
}
