import log from "loglevel";
import type { CacheEntry } from "./CacheEntry";

const DEFAULT_TTL = 24 * 60 * 60 * 1000;

/**
 * Sets a cached response in session storage.
 *
 * @typeParam T - The type of the value to be cached.
 *
 * @param key - The key under which the cached response will be stored.
 * @param value - The value to be cached.
 * @param ttl - The time-to-live for the cached response in milliseconds.
 *              Defaults to 24 hours if not provided.
 */
export function setCachedResponse<T>(
  key: string,
  value: T,
  ttl: number = DEFAULT_TTL
): void {
  try {
    // Create a cache entry for the value.
    const item: CacheEntry<T> = {
      value,
      timestamp: Date.now(),
      ttl,
    };

    // Store the cache entry.
    sessionStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    log.warn("Cache write failed:", error);
  }
}
