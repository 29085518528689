import { UserContext } from "@/plugins/Auth/state";
import { uuidRegExp } from "@/types/Brands/Uuid";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const uuid = uuidRegExp.toString().slice(2, -3);

export const CustomerRoutes: RouteConfig[] = [
  {
    path: "/customer",
    component: () =>
      import(/* webpackChunkName: "Ui" */ "@/components/Ui/component.vue"),
    beforeEnter(to: Route, from: Route, next: NavigationGuardNext<Vue>): void {
      if (UserContext.state.user) {
        if (UserContext.getters.isCustomer) {
          next();
        } else {
          next({ name: "CatchAll", params: { 0: to.fullPath } });
        }
      } else {
        sessionStorage.setItem("return-url", to.fullPath);
        next({ name: "App" });
      }
    },
    children: [
      // Profile
      {
        path: "profile",
        component: () =>
          import(
            /* webpackChunkName: "Customer.Profile" */ "@/components/View/Customer/Profile/View.vue"
          ),
        children: [
          {
            name: "Customer.Profile",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Customer.Profile" */ "@/components/View/Customer/Profile/FormView.vue"
              ),
          },
        ],
      },
      // Assignments
      {
        path: "assignments",
        component: () =>
          import(
            /* webpackChunkName: "Customer.Assignment" */ "@/components/View/Customer/Assignment/View.vue"
          ),
        children: [
          {
            name: "Customer.Assignments",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Customer.Assignment" */ "@/components/View/Customer/Assignment/TableView.vue"
              ),
          },
          {
            name: "Customer.AddAssignment",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Customer.Assignment" */ "@/components/View/Customer/Assignment/FormView.vue"
              ),
          },
          {
            name: "Customer.ShowAssignment",
            path: `:assignmentId(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Customer.Assignment" */ "@/components/View/Customer/Assignment/ShowView.vue"
              ),
          },
        ],
      },
      // Feedback
      {
        path: `assignments/:assignmentId(${uuid})/feedback`,
        component: () =>
          import(
            /* webpackChunkName: "Customer.Feedback" */ "@/components/View/Customer/Feedback/View.vue"
          ),
        children: [
          {
            name: "Customer.Feedback",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Customer.Feedback" */ "@/components/View/Customer/Feedback/FormView.vue"
              ),
          },
        ],
      },
      // Calendar
      {
        path: "calendar",
        component: () =>
          import(
            /* webpackChunkName: "Customer.Calendar" */ "@/components/View/Customer/Calendar/View.vue"
          ),
        children: [
          {
            name: "Customer.Calendar",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Customer.Calendar" */ "@/components/View/Customer/Calendar/ShowView.vue"
              ),
          },
        ],
      },
      // Invoice Details
      {
        path: "invoice-details",
        component: () =>
          import(
            /* webpackChunkName: "Customer.InvoiceDetails" */ "@/components/View/Customer/InvoiceDetails/View.vue"
          ),
        children: [
          {
            name: "Customer.InvoiceDetails",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Customer.InvoiceDetails" */ "@/components/View/Customer/InvoiceDetails/TableView.vue"
              ),
          },
        ],
      },
      // Users
      {
        path: "users",
        component: () =>
          import(
            /* webpackChunkName: "Customer.User" */ "@/components/View/Customer/User/View.vue"
          ),
        children: [
          {
            name: "Customer.Users",
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "Customer.User" */ "@/components/View/Customer/User/TableView.vue"
              ),
          },
          {
            name: "Customer.AddUser",
            path: "add",
            component: () =>
              import(
                /* webpackChunkName: "Customer.User" */ "@/components/View/Customer/User/FormView.vue"
              ),
          },
          {
            name: "Customer.EditUser",
            path: `:id(${uuid})`,
            component: () =>
              import(
                /* webpackChunkName: "Customer.User" */ "@/components/View/Customer/User/FormView.vue"
              ),
          },
        ],
      },
      // Sitemap
      {
        name: "Customer.Sitemap",
        path: "sitemap",
        component: () =>
          import(
            /* webpackChunkName: "Customer.Sitemap" */ "@/components/View/Customer/Sitemap/View.vue"
          ),
      },
      // Statistics
      {
        path: "statistics",
        component: () =>
          import(
            /* webpackChunkName: "Customer.Statistics" */ "@/components/View/Customer/Statistics/View.vue"
          ),
        children: [
          {
            name: "Customer.Statistics",
            path: "",
            redirect: "Customer.StatisticsCount",
          },
          {
            path: "count",
            component: () =>
              import(
                /* webpackChunkName: "Customer.Statistics" */ "@/components/View/Customer/Statistics/Count/View.vue"
              ),
            children: [
              {
                name: "Customer.StatisticsCount",
                path: "",
                component: () =>
                  import(
                    /* webpackChunkName: "Customer.Statistics" */ "@/components/View/Customer/Statistics/Count/FormView.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
];
