import { PREFIX } from "./PREFIX";

/**
 * Clears all cached entries.
 */
export function clearCache(): void {
  Object.keys(sessionStorage)
    .filter((item) => item.startsWith(PREFIX))
    .forEach((item) => sessionStorage.removeItem(item));
}
