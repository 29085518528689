/**
 * Serialize params into a query string with sorted params.
 *
 * @param params - Params to serialize
 *
 * @returns A query string with sorted params
 */
export function paramsSerializer(params: Record<string, unknown>): string {
  const query = new URLSearchParams();

  for (const key in params) {
    const value = params[key];
    if (
      value != null &&
      value !== "" &&
      !(Array.isArray(value) && value.length === 0) // not empty array
    ) {
      if (Array.isArray(value)) {
        for (const valueItem of value) {
          query.append(key, valueItem.toString());
        }
      } else {
        // the {} type means any non-nullish value.
        // eslint-disable-next-line @typescript-eslint/ban-types
        query.append(key, (value as {}).toString());
      }
    }
  }

  // Sort the params.
  query.sort();

  // Replace %20 with + and %2C with , and return.
  return query.toString().replace(/%20/g, "+").replace(/%2C/g, ",");
}
