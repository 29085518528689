/** HTTP methods defined by HTTP/1.0, HTTP/1.1 and RFC 5789. */
export enum HttpMethod {
  CONNECT = "CONNECT",
  DELETE = "DELETE",
  GET = "GET",
  HEAD = "HEAD",
  OPTIONS = "OPTIONS",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  TRACE = "TRACE",
}

/** HTTP methods for Remote Procedure Calls. */
export type RpcMethod = HttpMethod.POST;

/** HTTP methods for creating a resource. */
export type CreateMethod = HttpMethod.POST | HttpMethod.PUT;

/** HTTP methods for reading a resource. */
export type ReadMethod = HttpMethod.GET | HttpMethod.HEAD;

/** HTTP methods for updating a resource. */
export type UpdateMethod = HttpMethod.PATCH | HttpMethod.PUT;

/** HTTP methods for deleting a resource. */
export type DeleteMethod = HttpMethod.DELETE;
