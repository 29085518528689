import { HttpMethod } from "@/old-domain/enums/HttpMethod";
import { HttpStatusCode } from "@/old-domain/enums/HttpStatusCode";
import { Immutable } from "@/old-domain/types/Immutable";
import { JsonObject, JsonValue } from "@/old-domain/types/Json";
import { UserContext } from "@/plugins/Auth/state";
import { CacheOptions } from "../Cache/CacheOptions";
import { getCachedResponse } from "../Cache/getCachedResponse";
import { getCacheKey } from "../Cache/getCacheKey";
import { invalidateCache } from "../Cache/invalidateCache";
import { setCachedResponse } from "../Cache/setCachedResponse";
import { errorHandler } from "./errorHandler";
import { getApiUrl } from "./getApiUrl";
import { getAxios } from "./getAxios";
import { getCamelCaseHeaders } from "./getCamelCaseHeaders";
import { getParams } from "./getParams";
import { getUrl } from "./getUrl";
import { paramsSerializer } from "./paramsSerializer";
import { ApiRequest, ApiResponse, isAxiosError } from "./types";

export async function Api<T = JsonObject>(
  path: string,
  method: HttpMethod.GET | HttpMethod.POST, // Always return a body.
  request?: ApiRequest | Immutable<ApiRequest>,
  blob?: boolean,
  allowedErrorCodes?: HttpStatusCode[],
  useLocalCacheWorkaround?: boolean | CacheOptions,
  invalidateExistingCache?: boolean
): Promise<ApiResponse<T>>;
export async function Api<T = undefined>(
  path: string,
  method: HttpMethod.PUT | HttpMethod.OPTIONS, // May return a body but normally don't.
  request?: ApiRequest | Immutable<ApiRequest>,
  blob?: boolean,
  allowedErrorCodes?: HttpStatusCode[],
  useLocalCacheWorkaround?: boolean | CacheOptions,
  invalidateExistingCache?: boolean
): Promise<ApiResponse<T>>;
export async function Api(
  path: string,
  method: HttpMethod.HEAD | HttpMethod.DELETE | HttpMethod.PATCH, // Return 204s.
  request?: ApiRequest | Immutable<ApiRequest>,
  blob?: boolean,
  allowedErrorCodes?: HttpStatusCode[],
  useLocalCacheWorkaround?: boolean | CacheOptions,
  invalidateExistingCache?: boolean
): Promise<ApiResponse<undefined>>;
export async function Api<T = JsonObject>(
  path: string,
  method: Exclude<HttpMethod, HttpMethod.CONNECT | HttpMethod.TRACE>, // Internal fallback.
  request?: ApiRequest | Immutable<ApiRequest>,
  blob?: boolean,
  allowedErrorCodes?: HttpStatusCode[],
  useLocalCacheWorkaround?: boolean | CacheOptions,
  invalidateExistingCache?: boolean
): Promise<ApiResponse<T>>;
export async function Api<T = JsonObject>(
  path: string,
  method: Exclude<HttpMethod, HttpMethod.CONNECT | HttpMethod.TRACE>,
  request: ApiRequest | Immutable<ApiRequest> = {},
  blob = false,
  allowedErrorCodes: HttpStatusCode[] = [],
  useLocalCacheWorkaround?: boolean | CacheOptions,
  invalidateExistingCache?: boolean
): Promise<ApiResponse<T>> {
  /*
   * Caching is only allowed when:
   * - Using GET method
   * - No request counter is present
   * - No custom headers are present
   * - Response is not a blob
   */
  const cacheEnabled =
    useLocalCacheWorkaround &&
    method === HttpMethod.GET &&
    request.counter === undefined &&
    request.headers === undefined &&
    !blob;

  // Get Api URL, token type and access token.
  const apiUrl = await getApiUrl();
  const tokenType = UserContext.state.user?.tokenType;
  const accessToken = UserContext.state.user?.accessToken;

  // Extract version from path and remove it from path.
  const version = /^(v\d+)\//.exec(path)?.[1];
  if (version) {
    path = path.substring(version.length + 1);
  }

  // Get URL.
  const url = getUrl(path, request.ids);

  // Get params.
  const params = getParams(request);

  let cacheKey: string | undefined = undefined;
  if (cacheEnabled) {
    cacheKey = getCacheKey(url, params);
    if (invalidateExistingCache) {
      invalidateCache(cacheKey);
    } else {
      const cachedResponse = getCachedResponse<ApiResponse<T>>(cacheKey);
      if (cachedResponse) return cachedResponse;
    }
  }

  // Get Axios.
  const axios = await getAxios(apiUrl, tokenType, accessToken, version);

  try {
    const response = await axios.request<Blob | JsonValue | undefined>({
      method,
      url,
      params,
      paramsSerializer,
      data: request.data,
      headers: request.headers,
      responseType: blob ? "blob" : "json",
    });

    // Convert JSON blobs to objects.
    if (
      response.data instanceof Blob &&
      response.data.type === "application/json"
    ) {
      response.data = JSON.parse(await response.data.text());
    }

    // Get full requestUrl.
    let requestUrl = "";
    if (
      response.config.baseURL &&
      response.config.url &&
      typeof response.config.params === "object" &&
      response.config.params != null
    ) {
      requestUrl =
        response.config.baseURL +
        response.config.url +
        ("?" +
          Object.keys(response.config.params)
            .map(
              (key) =>
                `${key}=${encodeURIComponent(response.config.params[key])}`
            )
            .join("&"));
    }

    const apiResponse: ApiResponse<T> = {
      code: response.status,
      counter: request.counter,
      data: response.data as T | undefined,
      headers: getCamelCaseHeaders(response.headers),
      requestUrl,
      request,
    };

    if (cacheEnabled && cacheKey) {
      setCachedResponse(cacheKey, apiResponse);
    }

    return apiResponse;
  } catch (error: unknown) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (isAxiosError<any>(error)) {
      // Convert JSON blobs to objects.
      if (
        error.response &&
        error.response.data &&
        error.response.data instanceof Blob &&
        error.response.data.type === "application/json"
      ) {
        const jsonString = await error.response.data.text();
        error.response.data = JSON.parse(jsonString);
      }

      if (error.response && allowedErrorCodes.includes(error.response.status)) {
        return {
          code: error.response.status,
          counter: request.counter,
          data: error.response.data,
          headers: getCamelCaseHeaders(error.response.headers),
          requestUrl: url,
          request,
        };
      }

      const response = await errorHandler<T>(
        error,
        path,
        method,
        request,
        blob
      );

      if (response) {
        return response;
      }
    }

    throw error;
  }
}
